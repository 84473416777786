import "./About.css";

import { IoCheckmark } from "react-icons/io5";

import aboutImage from "../assets/aboutImage.jpg";

function About() {
  return (
    <div className="aboutContainer" id="sobre">
      <img src={aboutImage} alt="" className="imageTeaser" />
      <div className="aboutContent">
        <h1 className="aboutTitle">Sobre</h1>
        <p>
          Na Blu, não somos apenas uma assessoria contábil; somos seus parceiros
          dedicados a impulsionar o sucesso financeiro do seu negócio. Com uma
          equipe altamente qualificada, oferecemos expertise em contabilidade,
          folha de pagamento e consultoria tributária. Comprometidos com a
          inovação tecnológica, simplificamos e modernizamos a gestão
          financeira, garantindo eficiência em cada processo. Priorizamos a
          conformidade legal, assegurando que sua empresa esteja sempre em
          sintonia com as regulamentações vigentes. Somos uma parceria
          estratégica, indo além dos números para oferecer soluções
          personalizadas que estimulam o crescimento. Transparência e confiança
          são os alicerces dos relacionamentos que cultivamos, visando o sucesso
          duradouro dos nossos clientes. Escolha a Blu para uma assessoria
          contábil que supera expectativas, proporcionando a tranquilidade e
          confiança necessárias para concentrar-se no crescimento do seu
          negócio. Estamos aqui para ser a sua rota para o sucesso financeiro.
        </p>
        <div className="aboutListContainer">
          <h3>Por que escolher a Blu?</h3>
          <div className="aboutList">
          <ul className="whyChooseList">
            <li>
              <IoCheckmark className="aboutIcon" />
              Transparência e Confiança
            </li>
            <li>
              <IoCheckmark className="aboutIcon" />
              Parceria Estratégica
            </li>
            <li>
              <IoCheckmark className="aboutIcon" />
              Profissionais Qualificados
            </li>
          </ul>

          <ul className="whyChooseList">
            <li>
              <IoCheckmark className="aboutIcon" />
              Expertise Profissional
            </li>
            <li>
              <IoCheckmark className="aboutIcon" />
              Compromisso e Aprovação
            </li>
            <li>
              <IoCheckmark className="aboutIcon" />
              Inovação Tecnológica
            </li>
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
