import "./App.css";

import React from "react";

// COMPONENTS
import Header from "./Header";
import Home from "./components/Home";
import About from "./components/About";
import Form from "./components/Form";
import Services from "./components/Services";
import Clients from "./components/Clients";


import { FloatingWhatsApp } from "react-floating-whatsapp";

import logo from "./assets/logo_4x4.png";

function App() {
  return (
    <>
      <Header />
      <Home />
      <About />
      <Services />
      <Clients />
      <Form />
      <FloatingWhatsApp
        phoneNumber="5551997610969"
        accountName="Blu Empresarial"
        avatar={logo}
        statusMessage="On-Line"
        chatMessage="Olá, como posso ajudar?"
        onSubmit={() =>
          window.open("whatsapp://send?phone=5551997610969", "_self")
        }
        className="btnwtsp"
        allowEsc
        allowClickAway
        placeholder="Digite sua mensagem..."

      />
    </>
  );
}

export default App;
