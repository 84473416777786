import "./Clients.css";

//Clients

import cleber from '../assets/avatarImages/cleber.jpg'
import gabriel from '../assets/avatarImages/gabriel.jpg'
import henrique from '../assets/avatarImages/henrique.jpg'
import joao from '../assets/avatarImages/joao.jpg'
import pedro from '../assets/avatarImages/pedro.jpg'
import rogerio from '../assets/avatarImages/rogerio.jpg'


function Clients() {
  return (
    <div className="clientsContainer" id="clientes">
      <h1 className="clientTitle">Nossos Clientes</h1>
      <p className="clientSubtitle">
        Estes são alguns dos nosso clientes avaliando nosso serviços
      </p>
      <div className="avaliationContainer">
      <ul className="avaliationList">
        <li>
          <div className="avatarName">
            <img src={cleber} alt="" className="avatar" />
            <h3>Gabriel Pasquarelli</h3>
          </div>
          <p>
            A Blu não apenas oferece serviços contábeis de alta qualidade, mas
            também tem um impacto positivo tangível nos negócios de seus
            clientes. Sua expertise, inovação e compromisso com a conformidade
            permitem que as empresas atinjam novos patamares de eficiência,
            produtividade e sucesso financeiro. A contribuição da Blu para o
            crescimento de seus clientes é verdadeiramente notável.
          </p>
        </li>
        <li>
          <div className="avatarName">
            <img src={gabriel} alt="" className="avatar" />
            <h3>Rogerio Nascimento</h3>
          </div>
          <p>
            A relação entre a Blu e seus clientes é marcada por transparência e
            confiança mútuas. A empresa prioriza a comunicação aberta e honesta,
            fornecendo aos clientes todas as informações necessárias para
            tomadas de decisão informadas. Essa cultura de transparência cria
            uma base sólida para relacionamentos duradouros e bem-sucedidos.
          </p>
        </li>
        <li>
          <div className="avatarName">
            <img src={henrique} alt="" className="avatar" />
            <h3>Henrique Almeida </h3>
          </div>
          <p>
            A Blu vai além de uma simples prestadora de serviços contábeis; é
            uma verdadeira parceira estratégica para seus clientes. Sua
            abordagem centrada no cliente, combinada com uma compreensão
            profunda dos negócios de seus clientes, permite à empresa oferecer
            soluções personalizadas que impulsionam o crescimento e o sucesso
            empresarial.
          </p>
        </li>
      </ul>
      <ul className="avaliationList">
        <li>
          <div className="avatarName">
            <img src={joao} alt="" className="avatar" />
            <h3>Sousa de Melo </h3>
          </div>
          <p>
            A Blu demonstra um compromisso inabalável com a conformidade legal
            em todas as suas operações. A empresa investe recursos
            significativos para garantir que seus clientes estejam em total
            conformidade com as regulamentações fiscais e legais em constante
            evolução. Sua atenção aos detalhes e conhecimento atualizado das
            leis e normas são dignos de reconhecimento.
          </p>
        </li>
        <li>
          <div className="avatarName">
            <img src={pedro} alt="" className="avatar" />
            <h3>João Alves </h3>
          </div>
          <p>
            A Blu destaca-se pela sua abordagem inovadora no uso de tecnologia
            para simplificar e modernizar processos contábeis. Seu compromisso
            com a implementação das mais recentes ferramentas e sistemas garante
            eficiência e precisão em todos os aspectos da gestão financeira.
            Essa abordagem tecnológica posiciona a Blu como uma líder em seu
            setor.
          </p>
        </li>
        <li>
          <div className="avatarName">
            <img src={rogerio} alt="" className="avatar" />
            <h3>Pedro Silveira </h3>
          </div>
          <p>
            A Blu demonstra uma expertise profissional excepcional em todos os
            aspectos de sua atuação. Sua equipe altamente qualificada oferece
            serviços de contabilidade, folha de pagamento e consultoria
            tributária com um nível de competência que inspira confiança. A
            qualidade e precisão do trabalho realizado são testemunhos da
            experiência e conhecimento técnico da equipe da Blu.
          </p>
        </li>
      </ul>
      </div>
    </div>
  );
}

export default Clients;
