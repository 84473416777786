import { useState } from "react";
import emailjs from "@emailjs/browser";
import "./Form.css";

import { FiMapPin } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { CiInstagram } from "react-icons/ci";

function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function sendEmail(e) {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      message: message,
      email: email,
    };

    emailjs
      .send(
        "service_2hsts2d",
        "template_k4fp3q3",
        templateParams,
        "P_IWLMJpQ2cHM5EjX"
      )
      .then(
        (res) => {
          console.log("Email Enviado", res.status, res.text);
          setEmail("");
          setName("");
          setMessage("");
        },
        (err) => {
          console.log("Erro: ", err);
        }
      );
  }

  return (
    <div className="formContainer" id="contato">
      <h1 className="formTitle">Contato</h1>
      <p className="formSubtitle">
        Simplifique e fortaleça sua gestão financeira conosco. Nossa equipe está
        pronta para personalizar soluções que atendam às suas necessidades.
        Entre em contato e inicie seu caminho para o sucesso financeiro..
      </p>

      <div className="formContact">
        <form action="" onSubmit={sendEmail}>
          <div className="form">
            <input
              className="input"
              type="text"
              name="name"
              placeholder="Nome"
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              required
            />
            <input
              className="input"
              type="email"
              name="email"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              required
            />
            <textarea
              className="textArea"
              type="text"
              name="message"
              placeholder="Mensagem"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              value={message}
              required
            ></textarea>

            <input type="submit" className="input inputSubmit" />
          </div>
        </form>
        <div className="contact">
          <h3>Informações de contato:</h3>
          <ul>
            <li className="contactList">
              <h4>Endereço:</h4>
              <p>
                <FiMapPin className="pin" /> 17 Av. Alcídes São Severiano{" "}
              </p>
            </li>
            <li className="contactList">
              <h4>Email:</h4>
              <p>
                <MdOutlineEmail className="pin" />
                bluassessoria@outlook.com
              </p>
            </li>
            <li className="contactList">
              <h4>Telefone:</h4>
              <p>
                <BsTelephone className="pin" />
                +55 (51) 99939-4143
              </p>
              <p>
                <BsTelephone className="pin" />
                +55 (51) 99761-0969
              </p>
            </li>
            <li>
              <div className="socialMedia">
                <ul>
                  <li>
                    <a
                      href="https://www.instagram.com/bluassessoriaempresarial"
                      className="socialMediaLink"
                    >
                      {" "}
                      <CiInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Form;
