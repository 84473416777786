import "./Home.css";

import { Link } from "react-scroll";

function Home() {
  return (
    <div id="home" className="homeContainer">
      <div className="container">
        <h1>Sua Parceira estratégica para o sucesso financeiro</h1>

        <p>
          Bem-vindo à Blu, onde a inovação e a excelência se encontram para
          transformar o seu mundo empresarial. Somos uma empresa dedicada a
          criar soluções que transcendem as expectativas, combinando expertise e
          tecnologia para impulsionar seu sucesso.
        </p>
        <button>
          <Link
            to="sobre"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
        SOBRE
          </Link>
        </button>
      </div>
    </div>
  );
}

export default Home;
