import "./Services.css";

import { IoChatbubblesOutline } from "react-icons/io5";
import { IoIosCalculator } from "react-icons/io";
import { TbPigMoney } from "react-icons/tb";
import { LuBrain } from "react-icons/lu";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { HiOutlineReceiptTax } from "react-icons/hi";

function Services() {
  return (
    <div className="servicesContainer" id="serviços">
      <h1>Nossos Serviços</h1>

      <p className="servicesSubtitle">
        Na Blu, oferecemos expertise para otimizar seus processos e impulsionar
        seu sucesso. Simplifique, cresça e prospere conosco.
      </p>

      <ul className="servicesList">
        <li>
          <div className="icon servicesIcon">
            <IoIosCalculator />
          </div>
          <h3>BPO Contábil</h3>
          <p>
            Escolha a Blu para o seu Serviço de BPO Contábil e descubra uma
            parceria estratégica que impulsiona o sucesso do seu negócio. Nossa
            abordagem centrada no cliente e nossa expertise contábil garantem
            eficiência e precisão em cada aspecto do seu processo financeiro.
            Com a Blu, você pode confiar na conformidade legal, na inovação
            tecnológica e em soluções personalizadas que atendem às suas
            necessidades específicas. Simplifique sua gestão financeira e foque
            no crescimento do seu negócio com a Blu ao seu lado.
          </p>
        </li>

        <li>
          <div className="icon servicesIcon">
            <TbPigMoney />
          </div>
          <h3>BPO Financeiro</h3>
          <p>
            Descubra a eficiência e a tranquilidade de terceirizar suas
            operações financeiras com a Blu BPO Financeiro. Oferecemos soluções
            especializadas, desde contabilidade até processamento de folha de
            pagamento, permitindo que sua empresa concentre-se no que realmente
            importa: o crescimento. Conte com a experiência da Blu para otimizar
            processos, reduzir custos e garantir conformidade legal. Simplifique
            seu caminho para o sucesso financeiro com BPO Financeiro da Blu.
          </p>
        </li>

        <li>
          <div className="icon servicesIcon">
            <LuBrain />
          </div>
          <h3>Gestão e Estratégia</h3>
          <p>
            Na Blu, entendemos que uma gestão eficaz é a base do sucesso.
            Combinamos expertise em gestão e estratégia para guiar sua empresa
            rumo a novos patamares. Da definição de metas à execução de planos
            estratégicos, estamos aqui para impulsionar sua jornada de
            crescimento. Confie na Blu para transformar desafios em
            oportunidades e alcançar o sucesso empresarial sustentável.
          </p>
        </li>
      </ul>
      <ul className="servicesList secondServicesList">
        <li>
          <div className="icon servicesIcon">
            <FaRegMoneyBillAlt />
          </div>
          <h3>Gestão Tributária</h3>
          <p>
            Na Blu, entendemos a complexidade do cenário tributário. Oferecemos
            serviços especializados em gestão tributária para aliviar o fardo
            fiscal da sua empresa. Com expertise e atualização constante,
            otimizamos a conformidade e maximizamos seus benefícios fiscais.
            Deixe a Blu cuidar da sua gestão tributária, permitindo que você se
            concentre no crescimento do seu negócio.
          </p>
        </li>

        <li>
          <div className="icon servicesIcon">
            <IoChatbubblesOutline />
          </div>
          <h3>Sistema de Orçamento</h3>
          <p>
            Descubra o poder do Sistema de Orçamento da Blu para uma gestão
            financeira precisa e eficaz. Nosso sistema oferece ferramentas
            avançadas para criar, monitorar e ajustar orçamentos, proporcionando
            controle total sobre suas finanças. Simplifique o processo, tome
            decisões embasadas e alcance seus objetivos financeiros com
            confiança. Com a Blu, seu orçamento é mais do que números - é uma
            estratégia para o sucesso.
          </p>
        </li>
        <li>
          <div className="icon servicesIcon">
            <HiOutlineReceiptTax />
          </div>
          <h3>Imposto de Renda</h3>
          <p>
            Conte com a Blu para lidar com a sua declaração do Imposto de Renda.
            Nossa equipe de especialistas está comprometida em fornecer serviços
            detalhados e personalizados, assegurando precisão e conformidade com
            as regulamentações fiscais. Com a Blu, você pode ter tranquilidade
            sabendo que sua declaração será tratada com profissionalismo e
            cuidado, permitindo que você se concentre em outras áreas
            importantes da sua vida financeira.
          </p>
        </li>
      </ul>
    </div>
  );
}

export default Services;
