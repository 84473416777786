import { RxHamburgerMenu } from "react-icons/rx";

import Logo from "./assets/logo.png";

import "./Header.css";

import { Link } from "react-scroll";
import { useState } from "react";

function Header() {
  const [isOpen, setIsOpen] = useState("");

  function toogleIsOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <header className={`headerContainer ${isOpen ? "open" : ""}`}>
      <Link to="home" spy={true} smooth={true} offset={0} duration={500}>
        <img src={Logo} alt="Blu Serviços" className="logo" />
      </Link>

      <ul className={`headerList ${isOpen ? "open" : ""}`}>
        
        <li>
          <Link to="home" spy={true} smooth={true} offset={0} duration={500}>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="sobre"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            Sobre
          </Link>
        </li>
        <li>
          <Link
            to="serviços"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
          >
            Serviços
          </Link>
        </li>
        <li>
          <Link
            to="clientes"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
          >
            Clientes
          </Link>
        </li>
        <li>
          <Link
            to="contato"
            spy={true}
            smooth={true}
            offset={10}
            duration={500}
          >
            Contato
          </Link>
        </li>
        <li>
        <a href="http://client.bluassessoriaempresarial.com.br/">É cliente?</a>
        </li>
      </ul>
      <RxHamburgerMenu onClick={toogleIsOpen} className="hamburgerMenu" />
    </header>
  );
}

export default Header;
